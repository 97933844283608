import { Link } from "gatsby-plugin-react-i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const getSearchResults = (query) => {
  if (!query || !window.__LUNR__) return [];
  const lunrIndex = window.__LUNR__["de"];
  const results = lunrIndex.index.search(`*${query}*`); // you can  customize your search , see https://lunrjs.com/guides/searching.html
  return results.map(({ ref }) => lunrIndex.store[ref]);
};

const getDescription = ({ descriptionDe, descriptionEn }, language) =>
  language === "en" ? descriptionEn : descriptionDe;
const getTitle = ({ titleDe, titleEn }, language) =>
  language === "en" ? titleEn : titleDe;

const SearchResults = ({ expression }) => {
  const [t, i18n] = useTranslation();
  const [results, setResults] = useState([]);
  useEffect(() => {
    const loadResults = async () => {
      await window.__LUNR__.__loaded;
      setResults(getSearchResults(expression));
    };
    loadResults();
  }, [expression]);

  return (
    <div className="container">
      <div hidden={!expression}>
        {t("search:expression")}: <b>{expression}</b>
      </div>
      <div hidden={!expression}>
        {t("search:results")}: <b>{results.length}</b>
      </div>
      <div className="py-4">
        {results.map((result, idx) => (
          <Link
            to={result.url}
            key={idx}
            className="d-block py-3 border-bottom"
          >
            <h4>{getTitle(result, i18n.language)}</h4>
            <p className="text-nowrap text-truncate">
              {getDescription(result, i18n.language)}&nbsp;
            </p>
          </Link>
        ))}
        {results.length === 0 && !!expression && (
          <div className="font-weight-bold">
            {t("search:no-results", { expression })}
          </div>
        )}
        {results.length === 0 && !expression && (
          <div className="font-weight-bold">{t("search:placeholder")}</div>
        )}
      </div>
      <div className="pb-5" hidden={!expression}>
        {t("search:results")}: <b>{results.length}</b>
      </div>
    </div>
  );
};

export default SearchResults;
