import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PageBanner from "../components/Common/PageBanner";
import Layout from "../timewise-custom/components/App/Layout";
import Navbar from "../timewise-custom/components/App/Navbar";
import SEO from "../timewise-custom/components/App/SEO";
import withLocation from "../timewise-custom/components/HOC/withLocation";
import SearchResults from "../timewise-custom/components/Search/SearchResults";

const Search = ({ search }) => {
  const { t } = useTranslation();
  const [expression] = useState((search && search.expression) || "");
  const [formInput, setFormInput] = useState(
    (search && search.expression) || ""
  );

  return (
    <Layout>
      <SEO title={t("search:title")} />
      <Navbar />
      <PageBanner
        pageTitle={t("search:title")}
        homePageText={t("navigation:home")}
        homePageUrl="/"
        activePageText={t("search:slug")}
      />
      <div className="container pt-5 pb-2">
        <form action="/search" method="GET" className="search-box">
          <input
            autoComplete="off"
            type="text"
            name="expression"
            onChange={(event) => setFormInput(event.target.value)}
            value={formInput}
            className="input-search"
            placeholder={t("search:placeholder")}
          />
          <button type="submit">
            <i className="flaticon-loupe"></i>
          </button>
        </form>
      </div>
      <SearchResults expression={expression} />
    </Layout>
  );
};

export default withLocation(Search);
